<template>
  <section class="impressum-section bg-gray-100 py-16 mt-16 relative" id="impressum">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Heading -->
      <div class="text-center mb-12">
        <h2 class="text-3xl md:text-4xl font-extrabold text-gray-800">Impressum</h2>
      </div>

      <!-- Text Content -->
      <div class="text-lg text-gray-700 space-y-6">
        <p><strong>Anbieter:</strong></p>
        <p>Classic - Specialty Coffee<br> 
        Falckensteinstraße 4<br> 
        10997 Berlin, Deutschland</p>

        <p><strong>Kontakt:</strong><br> 
        Telefonnummer: +49 (176) 72384157<br> 
        E-Mail-Adresse: <a href="mailto:info@classic-speciality-coffee.de" class="text-blue-600 hover:underline">info@classic-speciality-coffee.de</a></p>

        <p><strong>Rechtlicher Hinweis:</strong><br> 
        Im Falle von Abweichungen zwischen der deutschen und der englischen Fassung hat die deutsche Fassung Vorrang.</p>
      </div>
    </div>

    <!-- Siegel unten rechts -->
    <img src="@/assets/img_siegel_v1_dark.png" alt="Impressum Siegel" class="impressum-seal">
  </section>
</template>

<script>
export default {
  name: 'ImpressumSection'
};
</script>

<style scoped>
.impressum-section {
  background-color: #f4f4f4;
  position: relative;
}

.impressum-seal {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 230px; /* Größe des Siegels anpassen */
  height: auto;
  opacity: 0.9;
}
</style>
