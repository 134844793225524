<template>
  <footer class="footer bg-gray-900 text-white py-16">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
        <!-- Informationen Section -->
        <div>
          <h3 class="text-xl font-bold mb-4">Informationen</h3>
          <ul class="text-gray-300">
            <li class="mb-2"><a href="#about" class="hover:text-teal-500">Philosophie</a></li>
            <li class="mb-2"><a href="#contactSection" class="hover:text-teal-500">Kontakt</a></li>
          </ul>
        </div>

        <!-- Gesetzliche Informationen Section -->
        <div>
          <h3 class="text-xl font-bold mb-4">Gesetzliche Informationen</h3>
          <ul class="text-gray-300">
            <li class="mb-2"><router-link to="/impressum" class="hover:text-teal-500">Impressum</router-link></li>
            <li class="mb-2"><router-link to="/datenschutz" class="hover:text-teal-500">Datenschutz</router-link></li>
            <li class="mb-2"><router-link to="/widerrufsrecht" class="hover:text-teal-500">Widerrufsrecht</router-link></li>
          </ul>
        </div>

        <!-- Map Section -->
        <div>
          <h3 class="text-xl font-bold mb-4">Ihr findet uns hier:</h3>
          <MapComponent class="map-custom-height" />
        </div>
      </div>

      <!-- Instagram Icon at the bottom center -->
      <div class="flex justify-center mt-8">
        <a href="https://www.instagram.com/classiccoffeebln" target="_blank" rel="noopener noreferrer" class="hover:text-teal-500">
          <img src="@/assets/instagram.svg" alt="Instagram" class="w-6 h-6 text-white hover:text-teal-500" />
        </a>
      </div>

      <div class="text-center mt-6">
        <p>© 2025 Classic - Specialty Coffee. Alle Rechte vorbehalten.</p>
      </div>
    </div>
  </footer>
</template>

<script>
import MapComponent from '@/components/MapComponent.vue';

export default {
  name: 'Footer',
  components: {
    MapComponent,
  },
};
</script>

<style scoped>
.footer {
  background: #333;
  color: white;
}

img {
  filter: brightness(0) invert(1); /* Macht das SVG-Icon weiß */
}

.map-custom-height {
  height: 200px; /* Höhe für die Karte */
}
</style>
