<template>
  <section class="about-us bg-gray-100 py-16" id="about">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Heading -->
      <div class="text-center mb-12">
        <h2 class="text-3xl md:text-4xl font-extrabold text-gray-800">Unsere Philosophie</h2>
        <p class="mt-4 text-lg md:text-xl text-gray-600">
          CLASSIC - Specialty Coffee bietet die beste Auswahl an Kaffeebohnen und exzellentem Service.
          Unser Ziel ist es, jeden Tag ein Stück Kaffee-Kultur zu Ihnen zu bringen.
        </p>
      </div>

      <!-- Team Section -->
      <div class="flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 md:space-x-20">
        <!-- Team Photo -->
        <div class="w-full md:w-1/3">
          <img src="@/assets/Aboutus.jpg" alt="Team Foto" class="rounded-lg shadow-lg w-full h-full object-cover">
        </div>
        
        <!-- About Text -->
        <div class="w-full md:w-1/3 text-center md:text-left">
          <p class="text-lg text-gray-700 mb-4">
            Wir sind ein leidenschaftliches Team von Kaffeeliebhabern, das sich der Kunst der Kaffeeröstung und dem Service verschrieben hat.
            Unsere Mission ist es, Ihnen den besten Kaffee zu servieren, der mit Sorgfalt und Liebe zubereitet wird.
          </p>
          <p class="text-lg text-gray-700">
            Bei CLASSIC Coffee glauben wir daran, dass guter Kaffee nicht nur ein Getränk ist, sondern ein Erlebnis. Besuchen Sie uns und erleben Sie es selbst!
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutUs'
};
</script>

<style scoped>
.about-us {
  background-color: #f4f4f4;
}
</style>
