<template>
  <div id="app">
    <Navigation />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Navigation from './components/Navigation.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    Navigation,
    Footer
  },
  watch: {
    // Beobachtet Routenänderungen und setzt den Seitentitel
    $route(to) {
      document.title = to.meta.title || 'CLASSIC - Specialty Coffee';
    }
  },
  created() {
    // Setzt den initialen Titel beim Laden der Seite
    document.title = this.$route.meta.title || 'CLASSIC - Specialty Coffee';
  }
};
</script>

<style>
/* Globale Stile können hier hinzugefügt werden */
</style>
