<template>
  <section class="datenschutz-section bg-gray-100 py-16 mt-16 relative" id="datenschutz">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Heading -->
      <div class="text-center mb-12">
        <h2 class="text-3xl md:text-4xl font-extrabold text-gray-800">Datenschutzerklärung</h2>
      </div>

      <!-- Text Content -->
      <div class="text-lg text-gray-700 space-y-6">
        <p><strong>Allgemein</strong></p>
        <p>Als Betreiber dieser Webseite und als Unternehmen kommen wir mit Ihren personenbezogenen Daten in Kontakt. Gemeint sind alle Daten, die etwas über Sie aussagen und mit denen Sie identifiziert werden können. In dieser Datenschutzerklärung möchten wir Ihnen erläutern, in welcher Weise, zu welchem Zweck und auf welcher rechtlichen Grundlage wir Ihre Daten verarbeiten.</p>

        <p><strong>Verantwortliche Stelle:</strong></p>
        <p>
          Classic - Specialty Coffee<br>
          Falckensteinstraße 4<br>
          10997 Berlin, Deutschland<br>
          Telefon: +49 (176) 72384157<br>
          E-Mail: <a href="mailto:info@classic-speciality-coffee.de" class="text-blue-600 hover:underline">info@classic-speciality-coffee.de</a>
        </p>

        <p><strong>SSL- bzw. TLS-Verschlüsselung</strong></p>
        <p>Wenn Sie Ihre Daten auf Webseiten eingeben, Online-Bestellungen aufgeben oder E-Mails über das Internet verschicken, müssen Sie immer damit rechnen, dass unberechtigte Dritte auf Ihre Daten zugreifen. Ein vollständiger Schutz vor solchen Zugriffen ist nicht möglich. Wir setzen jedoch alles daran, Ihre Daten bestmöglich zu schützen. Ein wichtiger Schutzmechanismus ist die SSL- bzw. TLS-Verschlüsselung unserer Webseite.</p>

        <p><strong>Wie lange speichern wir Ihre Daten?</strong></p>
        <p>Wir speichern Ihre Daten, bis der Zweck der Datenverarbeitung entfällt, Sie der Datenverarbeitung widersprechen oder Ihre Einwilligung widerrufen. Gesetzliche Aufbewahrungspflichten bleiben hiervon unberührt.</p>

        <p><strong>Ihre Rechte:</strong></p>
        <ul class="list-disc pl-5">
          <li>Widerspruch gegen die Datenverarbeitung (Art. 21 DSGVO)</li>
          <li>Widerruf Ihrer Einwilligung zur Datenverarbeitung (Art. 7 Abs. 3 DSGVO)</li>
          <li>Recht zur Beschwerde bei der Aufsichtsbehörde (Art. 77 DSGVO)</li>
          <li>Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</li>
          <li>Recht auf Auskunft, Berichtigung und Löschung (Art. 15–17 DSGVO)</li>
          <li>Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)</li>
        </ul>

        <p><strong>Hosting und Content Delivery Networks (CDN)</strong></p>
        <p>Unsere Webseite liegt auf Servern der folgenden Anbieter:</p>
        <p>
          <strong>Strato AG</strong><br>
          Otto-Ostrowski-Straße 7, 10249 Berlin
        </p>
        <p>
          <strong>Vercel Inc.</strong><br>
          440 N Barranca Ave #4133, Covina, CA 91723, USA
        </p>

        <p><strong>Server-Log-Dateien</strong></p>
        <p>Unsere Server protokollieren alle Zugriffe auf die Webseite und speichern automatisch technische Daten, wie:</p>
        <ul class="list-disc pl-5">
          <li>Browsertyp und -version</li>
          <li>Verwendetes Betriebssystem</li>
          <li>Referrer-URL</li>
          <li>Hostname des zugreifenden Rechners</li>
          <li>Uhrzeit der Serveranfrage</li>
          <li>IP-Adresse (ggf. anonymisiert)</li>
        </ul>
        <p>Diese Daten werden ausschließlich zur Verbesserung der Webseite und zur Fehleranalyse verwendet.</p>
      </div>
    </div>

    <!-- Siegel unten rechts -->
    <img src="@/assets/img_siegel_v2_dark.png" alt="Datenschutz Siegel" class="datenschutz-seal">
  </section>
</template>

<script>
export default {
  name: 'DatenschutzSection'
};
</script>

<style scoped>
.datenschutz-section {
  background-color: #f4f4f4;
  position: relative;
}

.datenschutz-seal {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 230px; /* Siegelgröße */
  height: auto;
  opacity: 1;
}
</style>
