<template>
  <nav class="bg-black text-white fixed w-full top-0 z-10 shadow-md">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between items-center h-16">
        <!-- Logo Section -->
        <div class="flex-shrink-0">
  <router-link to="/">
    <img src="@/assets/logo.png" alt="Classic Coffee Logo" class="h-10 w-auto" />
  </router-link>
</div>
        <!-- Desktop Navigation Links -->
        <div class="hidden md:flex space-x-8">
          <router-link :to="{ path: '/', hash: '#about' }" class="hover:bg-gray-800 px-4 py-2 rounded-md text-base font-medium transition duration-200">Philosophie</router-link>
          <router-link :to="{ path: '/', hash: '#bohnen' }" class="hover:bg-gray-800 px-4 py-2 rounded-md text-base font-medium transition duration-200">Unsere Bohnen</router-link>
          <router-link :to="{ path: '/', hash: '#gallery' }" class="hover:bg-gray-800 px-4 py-2 rounded-md text-base font-medium transition duration-200">Galerie</router-link>
          <router-link :to="{ path: '/', hash: '#contact' }" class="hover:bg-gray-800 px-4 py-2 rounded-md text-base font-medium transition duration-200">Kontakt</router-link>
        </div>
        <!-- Mobile Menu Button -->
        <div class="md:hidden flex items-center">
          <button @click="isOpen = !isOpen" type="button" class="bg-gray-700 inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-white transition duration-200">
            <span class="sr-only">Open main menu</span>
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- Mobile menu, show/hide based on menu state. -->
    <div :class="{'block': isOpen, 'hidden': !isOpen}" class="md:hidden bg-black">
      <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <router-link :to="{ path: '/', hash: '#about' }" class="block hover:bg-gray-800 px-3 py-2 rounded-md text-base font-medium transition duration-200">Philosophie</router-link>
        <router-link :to="{ path: '/', hash: '#bohnen' }" class="block hover:bg-gray-800 px-3 py-2 rounded-md text-base font-medium transition duration-200">Unsere Bohnen</router-link>
        <router-link :to="{ path: '/', hash: '#gallery' }" class="block hover:bg-gray-800 px-3 py-2 rounded-md text-base font-medium transition duration-200">Galerie</router-link>
        <router-link :to="{ path: '/', hash: '#contact' }" class="block hover:bg-gray-800 px-3 py-2 rounded-md text-base font-medium transition duration-200">Kontakt</router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navigation',
  data() {
    return {
      isOpen: false // Steuert die Sichtbarkeit des mobilen Menüs
    };
  }
};
</script>

<style scoped>
/* Zusätzliche Stile sind nicht erforderlich, da Tailwind CSS verwendet wird */
</style>
